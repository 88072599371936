import React from 'react'
import { cn } from '#app/utils/misc'

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
	as: string
}

const PlentyHeading = ({
	as = 'h1',
	className,

	children,
	...rest
}: HeadingProps) => {
	switch (as) {
		case 'h0':
			return (
				<h6
					className={cn(
						`text-[64px] leading-normal tracking-[-2.24px] text-black-90 md:text-[104px] md:tracking-[-3.64px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h1':
			return (
				<h6
					className={cn(
						`text-shadow text-[56px] leading-[62px] tracking-[-1.96px] text-black-90 md:text-[72px] md:leading-[62px] md:tracking-[-2.52px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h2':
			return (
				<h6
					className={cn(
						`text-shadow text-[48px] leading-normal tracking-[-1.68px] text-black-90 md:text-[64px] md:tracking-[-2.24px] `,
						className,
					)}
				>
					{children}
				</h6>
			)
		case 'h3':
			return (
				<h6
					className={cn(
						`text-shadow text-[40px] leading-normal tracking-[-1.4px] text-black-90 md:text-[56px] md:tracking-[-1.96px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h4':
			return (
				<h6
					className={cn(
						`text-shadow text-[32px] leading-none tracking-[-1.12px] text-black-90 md:text-[48px] md:leading-none md:tracking-[-1.68px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h5':
			return (
				<h6
					className={cn(
						`text-shadow text-[24px] leading-normal tracking-[-0.84px] text-black-90 md:text-[40px] md:tracking-[-1.4px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h6':
			return (
				<h6
					className={cn(
						`text-shadow tracking-[-0.84px]text-black-90 text-[24px] leading-normal md:text-[32px] md:tracking-[-1.12px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h7':
			return (
				<h6
					className={cn(
						`text-shadow text-[18px] leading-normal tracking-[-0.63px] text-black-90 md:text-[24px] md:tracking-[-0.84px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
		case 'h8':
			return (
				<h6
					className={cn(
						` text-[16px] leading-normal tracking-[-0.56px] text-black-90 md:text-[20px] md:tracking-[-0.70px]   `,
						className,
					)}
					{...rest}
				>
					{children}
				</h6>
			)
	}
}

export default PlentyHeading
